import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useCloseDealSetting() {
    const toast = useToast()
    const staffs = ref([]);
    const user = ref(JSON.parse(localStorage.getItem('userData')));
    const permission = user.value.ability.findIndex(s => s.action === 'manage' && s.subject === 'all');
    const rents = ref([]);
    const sales = ref([]);
    console.log('permission',permission);
   
    let user_id = ''; 
    if(permission < 0){
        user_id =  user.value.id;

    }
    const inpt = {
        staff_id:user_id,
        salary:null,
        salary_tax:0,
        commsions_tax:0,
        sso:0,
        other:'',
        other_value:null
      
    }
    const input = ref(JSON.parse(JSON.stringify(inpt)));
    
   // input.value = 
    console.log('user',user);
    const fetchAdmins = async()=>{
        const staff =   await store
        .dispatch('app-admin/fetchAdmins', {
          
            perpage:100,
            offset:0,
            groupType: 'admin'
        })
        if(staff.data.success){
            console.log('staffs',staff.data.data);
            const permission_a = user.value.ability.findIndex((s => s.action === 'all' && s.subject === 'CloseDeal' || (s.action === 'manage' && s.subject === 'all')));
          
            if(permission_a < 0){
                staffs.value = staff.data.data.filter((value)=>{
                    if(value.id == user.value.id){
                        return value;
                    }

                });
            }else{
                staffs.value = staff.data.data;
            }
            

        }
        fetchData();
    
    };
    const fetchData = async()=>{
        let data = {
          
            cond: '',
            commission: null,
            value: null,
        }
        let data_s = {
          
            cond: '',
            commission: null,
            value: null,
        }
        rents.value = [];
        sales.value = [];
      
        if(input.value.staff_id > 0){
            const response =   await store.dispatch('app-close-deal/getSetting',input.value.staff_id);
            console.log('response',response);
            if(response.data.data){
                const data = response.data.data;
                if(data.staff_id){
                    input.value = data;
                    if(data.rents.length > 0){
                        rents.value = data.rents;
    
                    }else{
                        rents.value.push(data);
                    }
                    if(data.sales.length > 0){
                        sales.value = data.sales;
    
                    }else{
                        sales.value.push(data_s);
                        
                    }
                }else{
                    let inpts = {
                        staff_id:input.value.staff_id,
                        salary:null,
                        salary_tax:0,
                        commsions_tax:0,
                        sso:0,
                        other:'',
                        other_value:null
                      
                    }
                    input.value = inpts;
                    rents.value.push(data);
                    sales.value.push(data_s);
                }
                
                
               

            }

        }else{
            
            rents.value.push(data);
            sales.value.push(data_s);
            
        }
        
        


    }
    
    fetchAdmins();
    const onSubmit = async()=>{
        input.value.sales = JSON.stringify(sales.value);
        input.value.rents = JSON.stringify(rents.value);
        
        console.log('input',input.value);
        const resp =   await store.dispatch('app-close-deal/updateSetting',input.value);
        if(resp.data.success){
            toast({
                component: ToastificationContent,
                props: {
                  title: "Update Success",
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })

        }else{
            toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching Category' list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })

        }
       

    }
    return {
        staffs,
        user,
        input,
        rents,
        sales,
        onSubmit,
        fetchData

    }
}