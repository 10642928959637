<template>
    <div>
        <b-row>
            <b-col md="6">
                <b-card-actions action-collapse title="Info Section">  
                   <b-form-group
                            label="Sale Name"
                            label-for="item-name"
                            >
                                <v-select
                                v-model="input.staff_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="staffs"
                                label="full_name"
                                class="invoice-filter-select mr-1"
                                placeholder="Select Staff"
                                :reduce="(option) => option.id"
                                @input="fetchData"
                                >
                                
                                </v-select>
                        
                            </b-form-group>
                     <b-form-group
                            label="หัก ณ ที่จ่าย commision (%)"
                            label-for="item-name"
                            >
                                <v-select
                                v-model="input.commsions_tax"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tax_withold"
                                label="text"
                                class="invoice-filter-select mr-1"
                                placeholder="Select Tax Withold"
                                :reduce="(option) => option.value"
                                >
                                
                                </v-select>
                        
                            </b-form-group>
                </b-card-actions>
               
            </b-col>
            <b-col md="6">
                <b-card-actions action-collapse title="Salary Section">  
                  <b-form-group
                    label="Salary"
                    label-for="salary"
                    >
                    <b-form-input
                        id="salary"
                        v-model="input.salary"
                        type="number"
                    
                    />
                    </b-form-group>
                    <b-form-group
                            label="หัก ณ ที่จ่าย salary (%)"
                            label-for="item-name"
                            >
                                <v-select
                                v-model="input.salary_tax"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tax_withold"
                                label="text"
                                class="invoice-filter-select mr-1"
                                placeholder="Select Tax Withold"
                                :reduce="(option) => option.value"
                                >
                                
                                </v-select>
                        
                            </b-form-group>
                    <b-form-group
                    label="ประกันสังคม"
                    label-for="sso"
                    >
                    <b-form-input
                        id="sso"
                        v-model="input.sso"
                        type="number"
                    
                    />
                
                    </b-form-group>
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                    label="อื่นๆ"
                    label-for="other"
                    >
                    <b-form-input
                        id="other"
                        v-model="input.other"
                    
                    />
                
                    </b-form-group>

                      </b-col>
                      <b-col md="6">
                        <b-form-group
                    label="บาท"
                    label-for="other_value"
                    >
                    <b-form-input
                        id="other"
                        v-model="input.other_value"
                        type="number"
                    
                    />
                
                    </b-form-group>

                      </b-col>
                    </b-row>
                </b-card-actions>
            </b-col>
            <b-col md="12">
                <b-card-actions action-collapse title="Rent Section">  
                    <b-form
                        ref="formRent"
                        :style="{height: trHeight}"
                        class="repeater-form"
                        @submit.prevent="repeateRentAgain"
                    >

                <!-- Row Loop -->
                        <b-row
                        v-for="(item, index) in rents"
                        :id="item.id"
                        :key="item.id"
                        ref="row"
                        >

                        <!-- Item Name -->
                        <b-col md="3">
                            <b-form-group
                            label="Commission Condition"
                            label-for="item-name"
                            >
                                <v-select
                                v-model="item.cond"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="conditions"
                                :reduce="(option) => option.value"
                                label="text"
                                class="invoice-filter-select mr-1"
                                placeholder="Select Condition"
                                >
                                
                                </v-select>
                        
                            </b-form-group>
                        </b-col>

                        <!-- Cost -->
                        <b-col md="3">
                            <b-form-group
                            label="Commission"
                            label-for="commission"
                            >
                            <b-form-input
                            v-model="item.commission"
                                id="commission"
                                type="number"
                            
                            />
                            </b-form-group>
                        </b-col>

                        <!-- Quantity -->
                        <b-col md="3">
                            <b-form-group
                            label="(%)"
                            label-for="value"
                            >
                            <b-form-input
                            v-model="item.value"
                                id="value"
                                type="number"
                            
                            />
                            </b-form-group>
                        </b-col>

                
                        
                        <!-- Remove Button -->
                        <b-col
                            lg="3"
                            md="3"
                            class="mb-50"
                        >
                            <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeItemRent(index)"
                            >
                            <feather-icon
                                icon="XIcon"
                                class="mr-25"
                            />
                            <span>Delete</span>
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <hr>
                        </b-col>
                        </b-row>
                

                    </b-form>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateRentAgain"
                    >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Add New</span>
                    </b-button>
                </b-card-actions>
            </b-col>
            <b-col md="12">
                <b-card-actions action-collapse title="Sale Section">  
                    <b-form
                        ref="formSale"
                        :style="{height: trHeight}"
                        class="repeater-form"
                        @submit.prevent="repeateSaleAgain"
                    >
                    <b-row
                        v-for="(itm, ind) in sales"
                        :id="'sale'+ind"
                        :key="'sale'+ind"
                        ref="row"
                        >

                        <!-- Item Name -->
                        <b-col md="3">
                            <b-form-group
                            label="Commission Condition"
                            label-for="itm-name"
                            >
                                <v-select
                                v-model="itm.cond"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="conditions"
                                :reduce="(option) => option.value"
                                label="text"
                                class="invoice-filter-select mr-1"
                                placeholder="Select Condition"
                                >
                                
                                </v-select>
                        
                            </b-form-group>
                        </b-col>

                        <!-- Cost -->
                        <b-col md="3">
                            <b-form-group
                            label="Commission"
                            label-for="commission"
                            >
                            <b-form-input
                            v-model="itm.commission"
                                id="commission"
                                type="number"
                            
                            />
                            </b-form-group>
                        </b-col>

                        <!-- Quantity -->
                        <b-col md="3">
                            <b-form-group
                            label="(%)"
                            label-for="sale-value"
                            >
                            <b-form-input
                            v-model="itm.value"
                                id="sale-value"
                                type="number"
                            
                            />
                            </b-form-group>
                        </b-col>

                
                        
                        <!-- Remove Button -->
                        <b-col
                            lg="3"
                            md="3"
                            class="mb-50"
                        >
                            <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeItemSale(index)"
                            >
                            <feather-icon
                                icon="XIcon"
                                class="mr-25"
                            />
                            <span>Delete</span>
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <hr>
                        </b-col>
                        </b-row>
                    </b-form>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateSaleAgain"
                    >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Add New</span>
                    </b-button>
                </b-card-actions>

            </b-col>
        </b-row>
        <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                   v-if="input.staff_id > 0"
                    @click="onSubmit"
                    >
                   
                    <span>Save</span>
                    </b-button>
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        v-else
       disabled
        >
        
        <span>Save</span>
        </b-button>             
        
       
      
    </div>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup,BFormDatepicker,BTable,BPagination,BImg ,BBadge
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



import router from '@/router'
import store from '@/store'
import adminStoreModule from '@/views/users/admin/adminStoreModule'
import useCloseDealSetting from './useCloseDealSetting'


import closedealStoreModule from '../closedealStoreModule'
export default {
    components: {
    BCardActions,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BTable,BPagination,
    BImg,
    BBadge,
    useCloseDealSetting

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      
      conditions:[
        {
            value:'less_than',
            text:'<'
        },
        {
            value:'less_than_equal',
            text:'<='
        },

        {
            value:'equal_to',
            text:'='
        },
        {
            value:'more_than',
            text:'>'
        },
        {
            value:'more_than_equal',
            text:'>='
        }


      ],
      tax_withold:[
        {
            value:0,
            text:'ไม่มี'
        },
        {
            value:3,
            text:'3%'
        },
        {
            value:5,
            text:'5%'
        },
        {
            value:7,
            text:'7%'
        },
        {
            value:10,
            text:'10%'
        }


      ],
      nextTodoId: 2,
    }
  },
  mounted() {
    //this.initTrRentHeight()
   // this.initTrSaleHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrRentHeight)
    window.addEventListener('resize', this.initTrSaleHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrRentHeight)
    window.removeEventListener('resize', this.initTrSaleHeight)
  },
  setup() {
    const ADMIN_APP_STORE_MODULE_NAME = 'app-admin'

    // Register module
    if (!store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) store.registerModule(ADMIN_APP_STORE_MODULE_NAME, adminStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) store.unregisterModule(ADMIN_APP_STORE_MODULE_NAME)
    });
    const CLOSE_DEAL_APP_STORE_MODULE_NAME = 'app-close-deal'

    // Register module
    if (!store.hasModule(CLOSE_DEAL_APP_STORE_MODULE_NAME)) store.registerModule(CLOSE_DEAL_APP_STORE_MODULE_NAME, closedealStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLOSE_DEAL_APP_STORE_MODULE_NAME)) store.unregisterModule(CLOSE_DEAL_APP_STORE_MODULE_NAME)
    });

    

    const {
      staffs,
      user,
      input,
      rents,
      sales,
      onSubmit,
      fetchData
      
    } = useCloseDealSetting();
    return {
      staffs,
      user,
      input,
      rents,
      sales,
      onSubmit,
      fetchData

    }
  },
  methods: {
    repeateRentAgain() {
   
      this.rents.push({
        condition: '',
            commission: null,
            value: null,
      })
      console.log('input', this.input);

      this.$nextTick(() => {
       // this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItemRent(index) {
      this.rents.splice(index, 1)
     // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrRentHeight() {
      //this.trSetRentHeight(null)
      this.$nextTick(() => {
        //this.trSetRentHeight(this.$refs.formRent.scrollHeight)
      })
    },
    repeateSaleAgain() {
      this.sales.push({
        condition: '',
            commission: null,
            value: null,
      })

      this.$nextTick(() => {
        ///this.trSaleAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItemSale(index) {
      this.sales.splice(index, 1)
     // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrSaleHeight() {
      //this.trSetSaleHeight(null)
      this.$nextTick(() => {
       // this.trSetSaleHeight(this.$refs.formSale.scrollHeight)
      })
    },
  },
    
}
</script>